import React, { } from 'react';
import './../../assets/styles/Footer.css'
import logoSvg from './../../assets/images/graviola-digital-logo.svg'


const Footer = ({ isInGames }) => {
    return (
        <div id="footer" className={isInGames ? "footer_container_games" : "footer_container"}>
            <p>
                &copy; Copyright Graviola Digital {new Date().getFullYear()}<br />sob autorização de Stenna.
            </p>
        </div>
    );
}

export const handleClick = event => {
    // window.open('www.google.com', '_blank')
}

export default Footer;
