import React, { } from 'react';
import logoSvg from './../../assets/images/logo-full.svg'
import './../../assets/styles/Logo.css'


const Logo = ({ appState }) => {
    return (
        <img className={appState === "landingpage" || appState === "login" ? "main_logo" : "logo_none"} src={logoSvg} />
    );
}

export default Logo;