
import "./../../assets/styles/OthersBooks.css";

import React, { useMemo, useState } from "react";
import Carousel from "nuka-carousel";
import TweenOne from "rc-tween-one";

import { playSong } from '../../utils/song'
import { songNames } from "../../enums/songs";

const slidesToShow = 1;

const OthersBooks = (props) => {
  const slides = useMemo(() => [...props.payload.listData].reverse(), [props.payload.listData])
  const isSurprise = useMemo(() => props.appState === "firsttimevisit" ? true : false, [props.appState]);

  const [currentSlide, setCurrentSlide] = useState(slides.length < 2 ? 0 : slides.length - 2)

  const handleClick = e => {
    const totalItens = slides.length;
    const id = e.target.id;

    for (let j = 0; j < totalItens; j++) {
      const element = slides[j];
      if (id === element.bookId) {
        setCurrentSlide(j)
        setTimeout(() => {
          props.onSlideChange(id);
          
          // aciona o scroll pro topo
          window.scrollTo(0,0)
        }, 200);
        // finaliza o "for"
        j = totalItens;
      }
    }
  }

  return (
    <div className="others_books_container_overflow">
      <TweenOne
        id="others_books"
        className={`others_books_container ${isSurprise ? "hide" : ""}`}
        paused={isSurprise}
        animation={{
          opacity: 1
        }}
      >
        <Carousel
          withoutControls={false}
          slidesToShow={slidesToShow}
          slideIndex={currentSlide}
          beforeSlide={() => {
            playSong(songNames.TAP)
          }}
          afterSlide={(index) => {
            setCurrentSlide(index)
            console.log('AFTER SLIDE', {currentSlide, index})
          }}
          renderBottomLeftControls={() => (
            <a
              className="others_books_nav_buttons text-white"
              onMouseEnter={() => {
                playSong(songNames.TAP)
              }}
              onClick={() => {
                setCurrentSlide(old =>{
                  if (old === 0) {
                    return old
                  }
                  return old - 1
                })
              }}
            >
              {"<<"}
            </a>
          )}
          renderBottomRightControls={() => (
            <a
              className="others_books_nav_buttons text-white"
              onMouseEnter={() => {
                playSong(songNames.TAP)
              }}
              onClick={() => {
                setCurrentSlide(old => {
                  const next = old + 1
                  if (next > slides.length - 1) {
                    return old
                  }
                  return next
                })
              }}
            >
              {">>"}
            </a>
          )}
        >
          {slides.map((item, index) => {
            return (
              <div key={item.bookId}>
                <div
                  id={item.bookId}
                  onClick={handleClick}
                  className="others_books_slide"
                  style={{ backgroundImage: `url(${item.bookCover})` }}
                />
                <p id="availabilityMonth">{item.bookMonthName}</p>
                <p id="availability">Baixe até</p>
                <p id="availabilityDate">{item.bookAvailability}</p>
              </div>
            )
          })}
        </Carousel>
      </TweenOne>
    </div>
  ) 
}

export default OthersBooks