import React, { useEffect, useState } from "react";
import axios from "axios";
import { Formik } from "formik";
import AuthHelp from "../auth-help";
import FaleConosco from "../faleconosco";
import { playSong } from '../../utils/song'
import { songNames } from '../../enums/songs'

import InputMask from "react-input-mask";
import { default_server } from "./../../services/api";
import { AUTH_TYPES } from "./consts";
import RadioButton from "../radio-button/index";
import robo from "../../assets/images/robo.png";

const help = `Adicionar texto de ajuda para essa seção.`

const AuthFormLogin = (props) => {

	const { method, phoneInput, toggle_callus, onSubmit, onSubmitError, onComplete, } = props;
	const screeHeight = window.screen.height;
	const buttonLabelDefault = "";
	const [BUTTON_LABEL, setButtonLabel] = useState(buttonLabelDefault);
	const regexTel =
		/^\(\d{2}\) \d{5}-\d{4}|\((?:1[2-9]|[2-9]\d)\) [5-9]\d{3}-\d{4}$/;
	const regexCnpjCpf =
		/^([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})$/;
	const [errorMsg, setErrorMessage] = useState("");
	const [radioCheck, setRadio] = useState(true);

	useEffect(() => {
		const buttonCPF = document.getElementById("button-cpf");
		if (!phoneInput && buttonCPF) {
			buttonCPF.disabled = !radioCheck;
		}
	}, [phoneInput, radioCheck]);

	useEffect(() => {
		phoneInput && setErrorMessage("");
	}, [phoneInput]);

	function ReturnButton() {
		return (
			<button
				id="button-cpf"
				className="auth_login_button_other"
				onMouseEnter={() => playSong(songNames.TAP)}
				onClick={() => {
					playSong(songNames.TAP)
					if (props && props.setMethod) {
						props.setMethod(null)
					}
				}}
			>
				Voltar
			</button>
		)
	}

	const getPageTitle = (authType) => {
		if (authType === AUTH_TYPES.CPF) {
			return 'Informe o<br />CPF/CNPJ'
		}
		if (authType === AUTH_TYPES.PHONE) {
			return 'Digite o celular<br />com DDD'
		}
		return `Digite o código<br />${method?.operadora}`
	}

	const getFields = (
		field,
		values,
		touched,
		errors,
		handleChange,
		handleBlur
	) => {
		//   console.log(errors.cpfcnpj, touched.cpfcnpj);
		if (field.name === "cpfcnpj") {
			if (field.validation !== "cpf_cnpj") {
				return (
					<div className="inputWrapper">
						<input
							className="input-cpf"
							value={values.cpfcnpj}
							name={field.name}
							key={field.name}
							placeholder={field.placeholder}
							pattern={!new RegExp(field.validation)}
							required
							onBlur={handleBlur}
							onChange={handleChange}
						/>
						{errors.cpfcnpj && touched.cpfcnpj && (
							<p className="auth_login_error">{errors.cpfcnpj}</p>
						)}
					</div>
				);
			} else {
				return (
					<div className="inputWrapper">
						<InputMask
							value={values.cpfcnpj}
							name={field.name}
							key={field.name}
							mask={
								values.cpfcnpj.length <= 14
									? "999.999.999-9999999"
									: "99.999.999/9999-99"
							}
							placeholder="CPF / CNPJ"
							maskChar={null}
							onChange={handleChange}
							className="input-cpf"
							onBlur={handleBlur}
						/>
						{errors.cpfcnpj && touched.cpfcnpj && (
							<p className="auth_login_error">{errors.cpfcnpj}</p>
						)}
					</div>
				);
			}
		}

		if (field.name === "msisdn") {
			return (
				<div className="inputWrapper">
					<InputMask
						type="tel"
						mask="(99) 99999-9999"
						maskChar={null}
						name={field.name}
						key={field.name}
						value={values.msisdn}
						onBlur={handleBlur}
						onChange={handleChange}
						placeholder={field.placeholder}
						required
						onClick={() => window.scrollTo(0, screeHeight * 0.25)}
					/>
					{errors.msisdn && touched.msisdn && (
						<p className="auth_login_error">{errors.msisdn}</p>
					)}
				</div>
			);
		}

		if (field.name === "username") {
			return (
				<div className="inputWrapper">
					<input
						type="text"
						name={field.name}
						placeholder={field.placeholder}
						key={field.name}
						value={values.username}
						required
						onChange={handleChange}
						onBlur={handleBlur}
					/>
					{errors.username && touched.username && (
						<p className="auth_login_error">{errors.username}</p>
					)}
				</div>
			);
		}

		if (field.name === "password") {
			return (
				<div className="inputWrapper">
					<input
						key={field.name}
						type="password"
						name="password"
						placeholder={field.placeholder}
						required
						onBlur={handleBlur}
						pattern={field.validation}
						value={values.password}
						onChange={handleChange}
						onClick={() => window.scrollTo(0, screeHeight * 0.25)}
					/>
					{errors.password && touched.password && (
						<p className="auth_login_error">{errors.password}</p>
					)}
				</div>
			);
		}
	};

	return (
		<>
			<h2 className="auth-page-content-h2" dangerouslySetInnerHTML={{ __html: getPageTitle(method.auth)}} />

			<div className="auth_pad_button_other">

				{/* //////////////////// */}
				{/* ////// FORMIK ////// */}
				{/* //////////////////// */}
				<Formik
					initialValues={{ msisdn: "", password: "", cpfcnpj: "", username: "" }}
					validate={(values) => {
						const errors = {};

						method.formfieldslogin.map((field) => {
							if (field.name === "username") {
								if (!new RegExp(field.validation).test(values.username)) {
									errors.username = field.validation_message;
								}

								if (values.username === "") {
									errors.username = "Campo obrigatório";
								}
							}

							if (field.name === "cpfcnpj") {
								if (field.validation === "cpf_cnpj") {
									if (values.cpfcnpj.match(regexCnpjCpf) === null) {
										errors.cpfcnpj = field.validation_message;
									}
								} else {
									if (!new RegExp(field.validation).test(values.cpfcnpj)) {
										errors.cpfcnpj = field.validation_message;
									}

									if (values.cpfcnpj === "") {
										errors.cpfcnpj = "Campo obrigatório";
									}
								}
							}
							if (field.name === "password") {
								if (!new RegExp(field.validation).test(values.password)) {
									errors.password = field.validation_message;
								}

								if (values.password === "") {
									errors.password = "Campo obrigatório";
								}
							}

							if (field.name === "msisdn") {
								if (values.msisdn.match(regexTel) === null) {
									errors.msisdn = field.validation_message;
								}

								if (values.msisdn === "") {
									errors.msisdn = "Campo obrigatório";
								}
							}
						});

						return errors;
					}}
					onSubmit={(values, { setSubmitting }) => {
						// props.onSubmit();
						setButtonLabel("• • •");

						const { idp, auth } = method;
						const data = {
							idp,
							auth: auth.toString(),
							produto: `${process.env.REACT_APP_DEFAULT_PRODUCT}`,
							tipo: "3",
						};

						const msisdn =
							values.msisdn && "55" + values.msisdn.replace(/[^a-zA-Z0-9]/g, "");

						if (!!msisdn) {
							data.msisdn = msisdn;
						}

						const password = values.password;

						if (!!password) {
							data.password = password;
						}

						const cpfcnpj = values.cpfcnpj;
						if (!!cpfcnpj) {
							data.cpfcnpj = cpfcnpj.replace(/[^0-9]+/g, "");
						}

						const username = values.username;

						if (!!username) {
							data.username = username;
						}

						axios({
							method: "post",
							timeout: 20000,
							url: `${default_server}/getportfolio/`,
							headers: {
								"Content-Type": "application/json",
							},
							data: JSON.stringify(data),
						})
							.then((response) => {
								setTimeout(() => props.onComplete(response.data), 1000);
							})
							.catch((error) => {
								setTimeout(() => {
									playSong(songNames.TROMPETE)
									props.onSubmitError()
								}, 1000);
							})
							.finally(() => {
								setTimeout(() => {
									try {
										setSubmitting(false);
									} catch (error) { }
									try {
										setButtonLabel(buttonLabelDefault);
									} catch (error) { }
								}, 1000);
							});
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
					}) => {
						return (
							<form onSubmit={handleSubmit}>
								{method.formfieldslogin.map((field) =>
									getFields(
										field,
										values,
										touched,
										errors,
										handleChange,
										handleBlur
									)
								)}
								{/* {!phoneInput && (
									<div className="checkbox-wrapper">
										<RadioButton radioCheck={radioCheck} setRadio={setRadio} />

										<p>Não sou um robô</p>
										<img src={robo} />
									</div>
								)} */}
								<button
									id="button-cpf"
									type="submit"
									disabled={BUTTON_LABEL !== ""}
									onMouseEnter={() => playSong(songNames.TAP)}
									className={`auth_login_button_login ${BUTTON_LABEL !== "" ? "remove-image" : null}`}
									onClick={() => () => playSong(songNames.TAP)}
								>
									{BUTTON_LABEL}
								</button>
								{!phoneInput && <p className="auth_login_error">{errorMsg}</p>}
							</form>
						);
					}}
				</Formik>
				{/* //////////////////////// */}
				{/* ////// END FORMIK ////// */}
				{/* //////////////////////// */}

				<br />
				<AuthHelp dicalogin={method?.dicalogin || help} />

				<div style={{ flex: 1, flexDirection: 'row' }}>
					<ReturnButton />
					<FaleConosco toggle_callus={toggle_callus} />
				</div>

			</div>
		</>
	);
};

export default AuthFormLogin;
