import React, { useState } from "react";
import "./../../assets/styles/FormNumpadPasscode.css";
import { default_server } from "./../../services/api";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
import { playSong } from "../../utils/song";
import { songNames } from "../../enums/songs";

const FormNumpadPasscode = (props) => {

  const { method } = props;
  const [senha, setSenha] = useState('');
  const [loading, setLoading] = useState(false);

  const notify = (text, time = 4000) => toast(text, {
    duration: time,
    // position: 'top-center',
    iconTheme: {
      primary: '#000',
      secondary: '#fff',
    },
  });

  const adicionarDigito = (digito) => {
    if (senha.length < 6) {
      setSenha(senha + digito);
    }
  };

  const apagarDigito = () => {
    setSenha(senha.slice(0, -1));
  };

  const enviarSenha = () => {

    if (!senha) {
      setLoading(true)
      setTimeout(() => setLoading(false), 1000);
      return notify('Digite o código', 3000)
    }

    const { idp, auth } = method;

    const data = {
      tipo: 3,
      idp,
      auth: auth,
      produto: `${process.env.REACT_APP_DEFAULT_PRODUCT}`,
    };

    const password = senha;

    if (!!password) {
      data.codigo = password;
    }

    setLoading(true)

    axios({
      method: "post",
      timeout: 20000,
      url: `${default_server}/getportfolio/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    })
      .then((response) => {
        setTimeout(() => props.onComplete(response.data), 1);
        notify('Código válido!', 6000)
      })
      .catch((error) => {
        setTimeout(() => {
          playSong(songNames.TROMPETE)
          props.onSubmitError()
        }, 400);
      })
      .finally(() => {
        setTimeout(() => {
          setSenha('')
          setLoading(false)
        }, 2000);
      })
  };

  return (
    <>
      <div className="auth_login_numpad">
        <div className="teclado">
          <div className="campo-senha">{senha.replace(/./g, '*')}</div>
          <div className="linha">
            <button className="button-pad" onClick={() => {playSong(songNames.TAP); adicionarDigito('1')}}>1</button>
            <button className="button-pad" onClick={() => {playSong(songNames.TAP); adicionarDigito('2')}}>2</button>
            <button className="button-pad" onClick={() => {playSong(songNames.TAP); adicionarDigito('3')}}>3</button>
          </div>
          <div className="linha">
            <button className="button-pad" onClick={() => {playSong(songNames.TAP); adicionarDigito('4')}}>4</button>
            <button className="button-pad" onClick={() => {playSong(songNames.TAP); adicionarDigito('5')}}>5</button>
            <button className="button-pad" onClick={() => {playSong(songNames.TAP); adicionarDigito('6')}}>6</button>
          </div>
          <div className="linha">
            <button className="button-pad" onClick={() => {playSong(songNames.TAP); adicionarDigito('7')}}>7</button>
            <button className="button-pad" onClick={() => {playSong(songNames.TAP); adicionarDigito('8')}}>8</button>
            <button className="button-pad" onClick={() => {playSong(songNames.TAP); adicionarDigito('9')}}>9</button>
          </div>
          <div className="linha">
            <button className="button-pad" onClick={() => {playSong(songNames.TAP); adicionarDigito('0')}}>0</button>
            <button className="button-erase" onClick={() => {playSong(songNames.TAP); apagarDigito()}} />
          </div>
        </div>
        <button onMouseEnter={() => playSong(songNames.TAP)} disabled={loading} className={`button-send ${loading ? 'auth-loading' : null}`} onClick={() => {playSong(songNames.TAP); enviarSenha()}} />
      </div>
      <Toaster />
    </>
  );
};

export default FormNumpadPasscode;
