import React from "react";
import "./../../assets/styles/MainTitle.css";

const MainTitle = props => {
  const { setIsOpenSurprise, onSurpriseComplete } = props;
  function handleClick() {
    setIsOpenSurprise(true);
    return setTimeout(onSurpriseComplete, 1500);
  }

  let BOOK = 0;
  const currentBookID = props.currentBookID;
  for (let i = 0; i < props.payload.listData.length; i++) {
    const element = props.payload.listData[i];
    if (element.bookId === currentBookID) {
      BOOK = i;
    }
  }

  const ITEM = props.payload.listData[BOOK];
  const isFirstVisit = props.appState === "firsttimevisit" ? true : false;
  const title = isFirstVisit ? "" : "aproveite sua aventura";
  const subtitle = isFirstVisit ? " " : ITEM.bookMonthName;


  return (
    <div id="main_title" className="main_title">
      <p className="main_title_text">
        <span onClick={() => handleClick()}>
          {title}
        </span>
        {/* {isFirstVisit? <span>
          no presente e descubra sua surpresa de {ITEM.bookMonthName}!
        </span>: ""} */}
      </p>
      <p className="main_title_subtext">{subtitle.toLowerCase()}</p>

    </div>
  );
};

export default MainTitle;
