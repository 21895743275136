import React, { useEffect, useState } from "react";
import "./../../assets/styles/AuthListPartners.css";
import imgLoading from "./../../assets/images/p-loader.gif";
import axios from "axios";
import { default_server } from "./../../services/api";
import AuthHelp from "../auth-help";
import AuthMenuLetter from "../auth-menu-letter";
import { playSong, stopSong } from "../../utils/song";
import { songNames } from "../../enums/songs";

const RenderGif = () => {
  useEffect(() => {
    playSong(songNames.PAGE_FLIP)

    return () => {
      stopSong(songNames.PAGE_FLIP)
    }
  }, [])

  return (
    <img
      src={imgLoading}
      alt={"Loading..."}
      className="auth_method_list_loading"
    />
  )
}

const AuthListPartners = (props) => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [method, setMethod] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios(`${default_server}/operadora/?produto=${process.env.REACT_APP_DEFAULT_PRODUCT}`).then((response) => {

      // Chame a função e obtenha os grupos de operadoras
      const operadoras = response.data.lista_operadoras; //operadoras.shift();
      const gruposDeOperadoras = organizarEagruparOperadoras(operadoras);

      // setData(response.data.lista_operadoras);
      setData(gruposDeOperadoras);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if ('callbackLodadeMethod' in props) {
      props.callbackLodadeMethod(method)
    }
  }, [method, props]);

  // Função para organizar e agrupar as operadoras
  function organizarEagruparOperadoras(operadoras) {
    // Organize as operadoras em ordem alfabética com base na propriedade "operadora"
    operadoras.sort((a, b) => a.operadora.localeCompare(b.operadora));

    // Crie um objeto para representar os grupos
    const grupos = {};

    // Itere pelos objetos e coloque as operadoras nos grupos apropriados
    operadoras.forEach((item) => {
      const operadora = item.operadora;
      const letraInicial = operadora.charAt(0).toUpperCase(); // Obtenha a primeira letra em maiúsculas

      if (!grupos[letraInicial]) {
        grupos[letraInicial] = []; // Crie um novo grupo se ainda não existir
      }

      grupos[letraInicial].push(item);
    });

    return grupos;
  }

  // Função para rolar para uma seção específica
  const scrollToSection = (sectionId) => {
    const targetElement = document.getElementById(sectionId);
    if (targetElement) {
      const topOffset = targetElement.getBoundingClientRect().top + window.pageYOffset;
      document.getElementById('auth_method_list').scrollTo({
        top: topOffset,
        behavior: 'smooth'
      });
    }
  };

  // Componente para exibir os grupos de operadoras
  function GruposDeOperadoras({ gruposDeOperadoras }) {
    return Object.entries(gruposDeOperadoras).map(([letra, operadoras], index) => (
      <div className="auth_method_list_letter" key={letra}>
        {index !== 0 && <h2 id={letra}>{letra}</h2>}
        <OperadoraList operadoras={operadoras} />
      </div>
    ))
  }

  // Componente para exibir a lista de operadoras em um grupo
  function OperadoraList({ operadoras }) {
    return (
      <div>
        {
          operadoras.map((operadora) => (
            <>
              <div
                className="auth_method_item"
                onMouseEnter={() => playSong(songNames.TAP)}
                onClick={() => {
                  playSong(songNames.TAP)
                  setMethod(operadora)
                }}
                key={operadora.operadora}
              >
                <div className="auth_method_item_infos">
                  <hr className="auth_method_list_separator" />
                  <span>{operadora.operadora}</span>
                </div>
              </div>
            </>
          ))
        }
        <hr className="auth_method_list_separator" />
        <br />
      </div>
    )
  }

  return (
    <div className="auth_method_login">
      <p className="auth_method_title">Selecione a empresa parceira</p>
      <AuthMenuLetter letterList={Object.keys(data || {})} scrollToSection={(id) => scrollToSection(id)} />
      <div id="auth_method_list" className={`auth_method_list ${!!data ? 'overflow-y-enabled' : null}`}>
        {loading ? (
          <RenderGif />
        ) : (
          (
            <GruposDeOperadoras
              gruposDeOperadoras={data} />
          )
        )}
      </div>
      {/* <AuthHelp dicalogin={'Adicionar texto de ajuda para essa seção.'} /> */}
    </div>
  );
};

export default AuthListPartners;

