export const songNames = {
  DOWNLOAD: 'download',
  MAIN_BACKGROUND: 'main_background',
  OCEAN_BUBBLES: 'ocean_bubbles',
  POING: 'poing',
  TAP: 'tap',
  TROMPETE: 'trompete',
  PAGE_FLIP: 'page_flip',
  OPEN_GAME: 'open_game',
  CLOSE_GAME: 'close_game',
}
