import '../../assets/styles/Pwa.css';

import React, { useEffect, useMemo } from 'react';
import { FiX } from 'react-icons/fi';
import { IoShareOutline } from 'react-icons/io5';
import { VscDiffAdded } from 'react-icons/vsc';
import logo from '../../assets/images/logo192.png'

import {
	isSafari,
	isIOSDevice,
	isSupportedInstallPrompt,
	isPWAInstaledIOS
} from '../../utils/device';
import { usePWAContext } from '../../context/pwaContext';

function PwaScreen() {
	const {
		showPWAModal,
		showPWAModalIOS,
		dismissIOSPWAModal,
		injectManifest,
		rejectPWA,
		acceptPWA,
	} = usePWAContext();

	useEffect(() => {
		injectManifest();
	}, [injectManifest]);

	const isSigned = useMemo(() => {
		return !!window.localStorage.getItem('@zoo/hash');
	}, []);

	/**
	 * Modal para o ANDROID e para os browsers suportados.
	 */
	function renderAskForinstall() {
		return (
			<div className="pwa-container">
				<div className="pwa-install-modal">
					<div className="pwa-content">
						{/* <div className="row">
						</div> */}
						<button className="pwa-close-button" onClick={rejectPWA} />
						<button className="pwa-install-button" onClick={acceptPWA} />
					</div>
				</div>
			</div>
		)
	}

	/**
	 * Modal para os IOS smartphones e tablets.
	 */
	function renderIOSInstall() {
		return (
			<div className="pwa-page-ios-container">
				<div className="pwa-page-ios-header">
					<img className="image-logo" src={logo} alt="Logo" />
					<p className="pwa-page-ios-header-title">Instale o ZooMoo Livros</p>

					<button className="pwa-page-ios-container-button" onClick={dismissIOSPWAModal}>
						<FiX />
					</button>
				</div>

				<div className="pwa-page-ios-content">
					<p className="pwa-page-ios-content-title">Instale o aplicativo em seu dispositivo para acessá-lo facilmente. Não precisa ir na loja. Não precisa fazer download.</p>

					<span className="steps steps-1">1. Pressione <IoShareOutline /></span>
					<span className="steps steps-2">2. Selecione <span className="text-hightliter">Adicionar à Tela de Início <VscDiffAdded /></span></span>
				</div>

				<div className="arrow-down" />
			</div>
		)
	}

	if ((isIOSDevice && !showPWAModalIOS && !isPWAInstaledIOS) || (isIOSDevice && !isSafari) || isPWAInstaledIOS || !isSigned) return null;
	if (!isIOSDevice && !showPWAModal) return null;

	return (
		<div className="pwa-page">
			{isSupportedInstallPrompt ? renderAskForinstall() : null}

			{!!(isIOSDevice && isSafari) ? renderIOSInstall() : null}
		</div>
	)
}

export default PwaScreen;