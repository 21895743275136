import React, { useCallback, useEffect, useState } from "react"
import SongBubblePlay from '../../assets/images/sound-bubble-play.png'
import SongBubblePause from '../../assets/images/sound-bubble-pause.png'
import { songNames } from "../../enums/songs"
import downloadSong from '../../assets/mp3/download.mp3'
import tapSong from '../../assets/mp3/tap.mp3'
import mainBackgroundSong from '../../assets/mp3/main_background.mp3'
import poingSong from '../../assets/mp3/poing.mp3'
import trompeteSong from '../../assets/mp3/trompete.mp3'
import pageFlipSong from '../../assets/mp3/page-flip.mp3'
import { storage } from '../../enums';
import aceanBubblesSong from '../../assets/mp3/ocean_bubbles.mp3'
import { useRef } from "react"
import songEvents from '../../events/song'
import { songEventsName } from '../../enums/events'
import openGameSong from '../../assets/mp3/open_game.mp3'
import closeGameSong from '../../assets/mp3/close_game.mp3'

export let songsFiles = {
  [songNames.DOWNLOAD]: downloadSong,
  [songNames.TAP]: tapSong,
  [songNames.OCEAN_BUBBLES]: aceanBubblesSong,
  [songNames.MAIN_BACKGROUND]: mainBackgroundSong,
  [songNames.POING]: poingSong,
  [songNames.TROMPETE]: trompeteSong,
  [songNames.PAGE_FLIP]: pageFlipSong,
  [songNames.OPEN_GAME]: openGameSong,
  [songNames.CLOSE_GAME]: closeGameSong,
}

const InternalSong = ({
  appState,
  isShowingGames,
  isReading
}) => {
  const songsRef = useRef(new Map())
  const floatButtonImageRef = useRef();
  const isDisableSongEffectsRef = useRef(!!window.localStorage.getItem(storage.hash));
  const [isSongDisabled, setIsSongDisabled] = useState(!!window.localStorage.getItem(storage.hash))

  const sendToIframeIsSongDisable = useCallback(() => {
    const gameManagerElement = window.document.getElementById('game-manager')
    if (gameManagerElement && gameManagerElement.contentWindow) {
      setTimeout(() => {
        gameManagerElement.contentWindow.postMessage({
          type: 'CHANGE_SONG_STATUS',
          payload: isSongDisabled
        }, '*')
      }, 300)
    }
  }, [isSongDisabled])

  useEffect(() => {
    let gameManagerElement;
    
    if (isShowingGames) {
      gameManagerElement = window.document.getElementById('game-manager')
      if (gameManagerElement) {
        gameManagerElement.addEventListener('load', () => {
          sendToIframeIsSongDisable()
        })
      }
    }

    return () => {
      if (gameManagerElement) {
        gameManagerElement.removeEventListener('load', () => {})
      }
    }
  }, [isShowingGames, sendToIframeIsSongDisable])

  useEffect(() => {
    sendToIframeIsSongDisable()
  }, [isShowingGames, isSongDisabled, sendToIframeIsSongDisable])

  useEffect(() => {
    Object.entries(songsFiles).forEach(([key]) => {
      const songElement = window.document.getElementById(`audio_${key}`)
      songsRef.current.set(key, songElement)

      songElement.addEventListener('timeupdate', function() {
        if ([songNames.PAGE_FLIP, songNames.MAIN_BACKGROUND].includes(key)) {
          var buffer = .88
          
          if(+this.currentTime.toFixed(2) > +(+this.duration.toFixed(2) - buffer).toFixed(2)){
            this.currentTime = 0
            this.play()
          }
        }
      });
    })
  }, [])

  const playSong =  (key) => {
    const song = songsRef.current?.get(key)
    
    if (song && !isDisableSongEffectsRef.current) {
      song.volume = 0.2;

      song.play().catch((error) => {
        console.error(error)
        if (['firsttimevisit', 'landingpage'].includes(appState)) {
          isDisableSongEffectsRef.current = true
          setIsSongDisabled(true)
        }
      })
    }
  }

  const stopSong = (key) => {
    const song = songsRef.current?.get(key)
    if (song) { 
      song.pause()
      song.currentTime = 0
    }
  }

  const pauseSong = (key) => {
    const song = songsRef.current?.get(key)
    if (song) {
      song.pause()
    }
  }

  const pauseAllAudios = () => {
    songsRef.current.forEach(song => {
      song.pause()
    })
  }

  const toggleBackgroundSong = () => {
    const nextIsDisableSongEffectsRefValue = !isDisableSongEffectsRef.current;
    isDisableSongEffectsRef.current = nextIsDisableSongEffectsRefValue
    setIsSongDisabled(nextIsDisableSongEffectsRefValue)
 
    if (!nextIsDisableSongEffectsRefValue) {
      if (window.location.hash.includes('jogos-')) {
        return
      }

      playSong(songNames.MAIN_BACKGROUND)
    } else {
      pauseAllAudios()
    }
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        if (!isDisableSongEffectsRef.current) {
          toggleBackgroundSong()
        }
      }
    };

    const handleWindowBlur = () => {
      if (document.activeElement.tagName !== 'IFRAME') {
        if (!isDisableSongEffectsRef.current) {
          toggleBackgroundSong()
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('blur', handleWindowBlur);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('blur', handleWindowBlur);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    songEvents.addListener(songEventsName.PLAY_SONG, playSong)
    songEvents.addListener(songEventsName.PAUSE_SONG, pauseSong)
    songEvents.addListener(songEventsName.STOP_SONG, stopSong)
 
    return () => {
      songEvents.removeListener(songEventsName.PLAY_SONG, playSong)
      songEvents.removeListener(songEventsName.PAUSE_SONG, pauseSong)
      songEvents.removeListener(songEventsName.STOP_SONG, stopSong)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {Object.entries(songsFiles).map(([key, file]) => (
        <audio
          key={`audio_${key}`}
          id={`audio_${key}`}
          src={file}
          preload='auto'
          autoPlay={false}
          muted={false}
        ></audio>
      ))}

      {appState === 'landingpage' && !isReading ? (
        <button id="FloatButton" onClick={toggleBackgroundSong}>
          <img ref={floatButtonImageRef} src={isSongDisabled ? SongBubblePause : SongBubblePlay} alt="Song icon" />
        </button>
      ) : null}
    </>
  )
}

export default InternalSong
