import React from "react";
import "./../../assets/styles/AuthMenuLetter.css";
import { playSong } from "../../utils/song";
import { songNames } from "../../enums/songs";

const AuthMenuLetter = (props) => {

  function MenuLetras() {
    const letras = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
    return (
      <div className="menu-letras">
        {letras.map((letra, index) => (
          <a onMouseEnter={() => playSong(songNames.TAP)} href={`#${letra}`} className="letra-quadrado" key={index} onClick={() => playSong(songNames.TAP)}>
            {letra}
          </a>
        ))}
      </div>
    );
  }

  return (
    <div className="menu-container">
      <MenuLetras />
    </div>
  );
};

export default AuthMenuLetter;
