import React, { useEffect, useState } from "react";
import "./../../assets/styles/AuthHelp.css";
import { AiFillQuestionCircle } from "react-icons/ai";
import Drawer from "react-drag-drawer";
import { playSong } from "../../utils/song";
import { songNames } from "../../enums/songs";

const AuthHelp = (props) => {

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div 
        className="button-help"
        onClick={() => {
          playSong(songNames.TAP)
          setModalOpen(true)
        }}
      >
        <AiFillQuestionCircle onMouseEnter={() => playSong(songNames.TAP)} size={40} />
      </div>
      <Drawer dontApplyListeners={true} open={modalOpen} onRequestClose={() => setModalOpen(false)}>
        <div className="modal">
          <div className="modal-container">
            <p style={{ textAlign: "left" }}>{props?.dicalogin}</p>
          </div>
          <div className="modal-button-container">
            <button
              onMouseEnter={() => playSong(songNames.TAP)}
              onClick={() => {
                playSong(songNames.TAP)
                setModalOpen(false)
              }}
            >
              FECHAR
            </button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default AuthHelp;
