import React, { useState } from "react";
import Drawer from "react-drag-drawer";
import "./../../assets/styles/InfoButtons.css";
import CallUsModal from "../faleconosco/faleconosco-modal";
import { playSong } from "../../utils/song";
import { songNames } from "../../enums/songs";

const InfoButtons = ({ closeGameManager }) => {
  const [show_about, toggle_about] = useState(false);
  const [show_doubt, toggle_doubt] = useState(false);
  const [show_callus, toggle_callus] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  return (
    <div id="info_buttons" className={closeGameManager ? 'game_manager_buttons' : 'info_buttons_container'}>
      <div className="info_buttons_wrap">
        {closeGameManager ? (
          <button
            id="close_game_manager"
            onClick={closeGameManager}
          >
            Página inicial
          </button>
        ) : null}

        <button
          id="about"
          onMouseEnter={() => playSong(songNames.TAP)}
          onClick={() => {
            playSong(songNames.TAP)
            toggle_about(true)
          }}
        >
          SOBRE NÓS
        </button>
        <button
          id="faq"
          onMouseEnter={() => playSong(songNames.TAP)}
          onClick={() => {
            playSong(songNames.TAP)
            toggle_doubt(true)
          }}
        >
          DÚVIDAS
        </button>
        <button
          id="contact"
          onMouseEnter={() => playSong(songNames.TAP)}
          onClick={() => {
            playSong(songNames.TAP)
            toggle_callus(true);
            setIsSuccess(false);
          }}
        >
          FALE CONOSCO
        </button>
        <button
          id="contact"
          onMouseEnter={() => playSong(songNames.TAP)}
          onClick={() => {
            playSong(songNames.TAP)
            playSong(songNames.OCEAN_BUBBLES)
            window.localStorage.removeItem('@zoo/hash');

            setTimeout(() => {
              window.location.reload();
            }, 700)
          }}
        >
          SAIR
        </button>

        {closeGameManager ? <div className="game_manager_space_bottom" /> : null}
      </div>

      <Drawer dontApplyListeners={true} open={show_about} onRequestClose={() => toggle_about(false)}>
        <div className="modal">
          <div className="modal-container">
            <h2>SOBRE NÓS</h2>
            <p>
              O ZooMoo Kids é um canal infantil seguro e de qualidade, que une entretenimento, educação e inclusão, promovendo valores essenciais como respeito, empatia e amizade.
            </p>
            <p>
              O ZooMoo Livros traz os mesmos valores e a mesma qualidade do canal para os livros, fazendo com que as crianças de até 10 anos adquiram o hábito e o gosto pela leitura. Todos os meses o ZooMoo Livros apresenta novos títulos cuidadosamente selecionados para ler online, ouvir e baixar para guardar para sempre! Os pequenos se divertem e aprendem que a leitura pode ser tão prazerosa quanto qualquer outra forma de entretenimento.
            </p>
          </div>
          <div className="modal-button-container">
            <button
              onMouseEnter={() => playSong(songNames.TAP)}
              onClick={() => {
                playSong(songNames.TAP)
                toggle_about(false)
              }}
            >
              FECHAR
            </button>
          </div>
        </div>
      </Drawer>

      <Drawer
        dontApplyListeners={true}
        modalElementClass="card"
        parentElement={document.body}
        direction="bottom"
        open={show_doubt}
        onRequestClose={() => toggle_doubt(false)}
      >
        <div className="modal">
          <div className="modal-container">
            <h2>DÚVIDAS</h2>

            <h3>Para que idade os livros são adequados?</h3>
            <p>
              Os livros são adequados para todos os públicos. Assim como a programação do canal ZooMoo Kids, os conteúdos do ZooMoo Livros são selecionados especialmente para crianças de até 10 anos.
            </p>

            <br />

            <h3>Como faço para ler os livros do ZooMoo Livros?</h3>
            <p>
              É simples! Você pode ler os livros online ou baixá-los e guardá-los para sempre! Para ler online, basta selecionar o livro que você quer ler, seja o livro do mês ou de meses anteriores, clicando sobre a capa. Aí é so clicar em “LER ONLINE” e passar as páginas. A leitura online é acompanhada de narração. Mas se quiser fazer o download do livro, clique em “BAIXAR”, e o livro será baixado diretamente para o seu dispositivo. Para ler, você precisará ter um leitor digital (e-reader) instalado. Há várias opções gratuitas disponíveis, como o Play Livros, para dispositivos Android, o Livros (ou Books), para dispositivos iOS, e o Adobe Digital Editions para computador. Normalmente, os livros baixados são recarregados diretamente no seu leitor digital. Mas se isso não acontecer, procure o livro na área de downloads do seu dispositivo.
            </p>

            <br />

            <h3>Posso compartilhar o conteúdo com outras pessoas?</h3>
            <p>
              Todos os conteúdos do ZooMoo Livros são protegidos por direitos autorais, pois isso é proibido copiar, imprimir, ceder ou compartilhar. Mas incentivamos você a fazer a leitura compartilhada dos livros com as crianças.
            </p>

            <br />

            <h3>Por que não encontro mais um livro?</h3>
            <p>
              Cada livro fica disponível no ZooMoo Livros por um ano. Se você estiver procurando por um livro disponibilizado há mais tempo, não vai encontrá-lo. Por isso, não esqueça de baixar seus livros antes de 12 meses. Os livros baixados são seus para sempre!
            </p>

            <br />

            <h3>Por que não consigo ouvir a narração de um livro?</h3>
            <p>
              O recurso de narração está disponível apenas para a leitura online. Por isso, se você estiver lendo um livro baixado, não vai conseguir acessar o áudio. Para ouvir a narração online, basta abrir o livro, clicando em “LER”, e acionar o ícone da narração no canto superior esquerdo.
            </p>

            <br />

            <h3>Posso acessar a programação do canal ZooMoo Kids pelo ZooMoo Livros?</h3>
            <p>
              A programação do ZooMoo está disponível apenas na TV, o ZooMoo Livros traz outros conteúdos, para que as crianças possam desfrutar de outras formas.
            </p>

            <br />

            <h3>Como posso ter os livros impressos?</h3>
            <p>
              Todos os livros disponibilizados no ZooMoo Livros existem em versões físicas, impressas, e podem ser adquiridos nas livrarias ou pela internet, nas lojas virtuais.
            </p>

            <br />

            <h3>Não encontrou sua dúvida aqui? Então envie sua pergunta pelo Fale Conosco!</h3>
          </div>
          <div className="modal-button-container">
            <button
              onMouseEnter={() => playSong(songNames.TAP)}
              onClick={() => {
                playSong(songNames.TAP)
                toggle_doubt(false)
              }}>
                FECHAR
              </button>
          </div>
        </div>
      </Drawer>
      <CallUsModal
        show_callus={show_callus}
        toggle_callus={toggle_callus}
        isSuccess={isSuccess}
        setIsSuccess={setIsSuccess}
        show_callus_title={'FALE CONOSCO'}
        show_callus_text1={'Ficou com dúvida ou quer dar sua opinião? Escreva para o ZooMoo Livros!'}
        show_callus_text2={'Vamos ler e responder sua mensagem rapidinho!'}
      />
    </div>
  );
};

export default InfoButtons;
