import React, { useState } from 'react';
import Drawer from 'react-drag-drawer';
import Regulamento from './regulamento';
import SeuLivroAqui from './seuLivroAqui';
import './../../assets/styles/InfoSmallButtons.css';
import { songNames } from '../../enums/songs';
import { playSong } from '../../utils/song';

const InfoSmallButtons = () => {
	const [show_terms, toggle_terms] = useState(false);
	const [show_participe, toggle_participe] = useState(false);
	return (
		<div id="small_buttons" className="info_small_buttons_container">
			<p
				onMouseEnter={() => playSong(songNames.TAP)}
				onClick={() => {
					playSong(songNames.TAP)
					toggle_terms(true)
				}}
				>
					Regulamento
				</p>

			<Drawer dontApplyListeners={true} open={show_terms} onRequestClose={() => toggle_terms(false)}>
				<Regulamento onRequestClose={() => toggle_terms(false)} />
			</Drawer>

			<Drawer
				dontApplyListeners={true}
				open={false}
				// open={show_participe}
				onRequestClose={() => toggle_participe(false)}
			>
				<SeuLivroAqui onRequestClose={() => toggle_participe(false)} />
			</Drawer>
		</div>
	);
};

export default InfoSmallButtons;
