import React from "react";
import "./../../assets/styles/FaleConosco.css";
import { playSong } from "../../utils/song";
import { songNames } from "../../enums/songs";

const FaleConosco = (props) => {
    return (
        <button
            onMouseEnter={() => playSong(songNames.TAP)}
            onClick={() => {
                playSong(songNames.TAP)
                if (props && props.toggle_callus) {
                    props.toggle_callus(
                        true,
                        'FALE CONOSCO',
                        'Ficou com dúvida ou quer dar sua opinião? Escreva para o ZooMoo Livros!',
                        'Vamos ler e responder sua mensagem rapidinho!'
                    )
                }
            }}
            id="contact"
            className="fale-conosco-button"
        >
            Fale conosco
        </button>
    );
};

export default FaleConosco;
