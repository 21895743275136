import React from "react";
import { playSong } from '../../utils/song'
import { songNames } from "../../enums/songs";

const Regulamento = props => {
  return (
    <div className="modal-regulamento">
      <div className="modal-regulamento-container">
        <p><strong>REGULAMENTO DA PLATAFORMA ZOOMOO LIVROS</strong></p>
        <p>&nbsp;</p>
        <p><strong>1. O OBJETO</strong></p>
        <p>O Presente Termo regula as condi&ccedil;&otilde;es de uso do ZooMoo Livros, plataforma de livros infantis digitais que permite a seus usu&aacute;rios, doravante Participantes Usu&aacute;rios, ter acesso mensalmente um ou mais t&iacute;tulos em formato eletr&ocirc;nico para leitura on-line em seus dispositivos como smartphone, tablet ou computador.</p>
        <p>O Regulamento ora apresentado, segue, dentre outros, os princ&iacute;pios da informa&ccedil;&atilde;o, transpar&ecirc;ncia e boa-f&eacute;, observando as leis n&ordm; 8.078/1990 e n&ordm; 10.406/2002.</p>
        <p>Os Participantes Usu&aacute;rios declaram que ao acessarem e utilizarem a plataforma ZooMoo Livros leram integralmente e aceitaram os termos deste Regulamento na forma como aqui expressos, tendo acesso aos t&iacute;tulos distribu&iacute;dos.</p>
        <p><br /></p>
        <p><strong>2. O ACESSO AO ZOOMOO LIVROS</strong></p>
        <p>2.1. Poder&atilde;o ser Participantes Usu&aacute;rios somente as pessoas jur&iacute;dicas e as pessoas f&iacute;sicas capazes maiores de 18 (dezoito) anos.</p>
        <p>2.2. O Participante Usu&aacute;rio, nos termos da legisla&ccedil;&atilde;o aplic&aacute;vel &agrave; esp&eacute;cie, declara que a partir do in&iacute;cio da utiliza&ccedil;&atilde;o da plataforma, e consequente aceita&ccedil;&atilde;o deste Regulamento, ser&aacute; respons&aacute;vel pelos seus atos na utiliza&ccedil;&atilde;o do ZooMoo Livros, no caso de qualquer conduta il&iacute;cita, na forma das legisla&ccedil;&otilde;es vigentes, bem como pela eventual utiliza&ccedil;&atilde;o indevida ou por propaga&ccedil;&atilde;o do conte&uacute;do disponibilizado sem pr&eacute;via e expressa autoriza&ccedil;&atilde;o.</p>
        <p>2.3. Ap&oacute;s ci&ecirc;ncia de todos os termos do presente regulamento, o Participante Usu&aacute;rio ter&aacute; acesso aos conte&uacute;dos digitais adquiridos, para leitura on-line na plataforma, sendo de sua inteira faculdade baixar os t&iacute;tulos oferecidos, observando os prazos previstos no item 3.5.</p>
        <p>2.4. Os t&iacute;tulos baixados ser&atilde;o disponibilizados em arquivos formato epub, que ficam armazenados no dispositivo do Usu&aacute;rio Participante.</p>
        <p>2.5. Antes baixar os t&iacute;tulos disponibilizados, o Participante Usu&aacute;rio dever&aacute; reler integralmente este Regulamento. Caso opte pelo download de livros, o Usu&aacute;rio concordar&aacute; automaticamente que houve expressa aceita&ccedil;&atilde;o e concord&acirc;ncia de todas as regras, termos e condi&ccedil;&otilde;es previstos neste Regulamento. N&atilde;o ser&aacute; poss&iacute;vel a utiliza&ccedil;&atilde;o do ZooMoo Livros caso haja qualquer discord&acirc;ncia aos presentes termos.</p>
        <p>2.6. O Participante Usu&aacute;rio se declara propriet&aacute;rio dos exemplares que lhe foram disponibilizados na plataforma ZooMoo Livros.</p>
        <p>2.7. &Eacute; de responsabilidade exclusiva dos Participantes Usu&aacute;rios a op&ccedil;&atilde;o pelo download dos t&iacute;tulos oferecidos, assim como o fornecimento de acesso ao conte&uacute;do para menores de 18 anos de idade que assiste ou representa, nos termos da legisla&ccedil;&atilde;o civil brasileira, perante os quais o Participante Usu&aacute;rio desempenhar&aacute; o papel de mediador, observando o disposto no art. 73 da lei n&ordm; 8.069/1990.</p>
        <p>2.8. A despeito da criteriosa sele&ccedil;&atilde;o de t&iacute;tulos e curadoria do conte&uacute;do oferecido, o ZooMoo Livros n&atilde;o se responsabiliza, em hip&oacute;tese alguma, por quaisquer danos que a exposi&ccedil;&atilde;o do conte&uacute;do possa causar, particularmente sobre p&uacute;blico inadequado, bem como o acesso a plataforma por menores de 18 (dezoito) anos, cabendo ao Participante Usu&aacute;rio, no papel de mediador, fazer a avalia&ccedil;&atilde;o sobre a adequa&ccedil;&atilde;o e conveni&ecirc;ncia da exposi&ccedil;&atilde;o do conte&uacute;do, sobretudo ao p&uacute;blico infantil.</p>
        <p><br /></p>
        <p><strong>3. A PARTICIPA&Ccedil;&Atilde;O E A UTILIZA&Ccedil;&Atilde;O DE CONTE&Uacute;DO</strong></p>
        <p>3.1. O ZooMoo Livros &eacute; uma plataforma exclusiva, de ades&atilde;o volunt&aacute;ria pelos Participantes Usu&aacute;rios, por meio de contrata&ccedil;&atilde;o direta ou v&iacute;nculo com as empresas parceiras do ZooMoo Livros. A elegibilidade ao acesso &agrave; plataforma, salvo contrata&ccedil;&atilde;o direta, &eacute; definida pelas empresas parceiras a seu exclusivo crit&eacute;rio, cabendo aos eleg&iacute;veis a decis&atilde;o de consumar ou n&atilde;o sua ades&atilde;o por meio do download dos livros.</p>
        <p>3.2. &Eacute; direito dos Participantes Usu&aacute;rios, a qualquer tempo e sem necessidade de justificativa pr&eacute;via, solicitar a suspens&atilde;o do envio de comunica&ccedil;&otilde;es pelo ZooMoo Livros mediante comunica&ccedil;&atilde;o expressa, diretamente ao ZooMoo Livros por meio da se&ccedil;&atilde;o Fale Conosco ou aos canais de relacionamento da empresa parceira a qual esteja vinculado. A suspens&atilde;o de comunicados n&atilde;o cancela a elegibilidade dos Participantes Usu&aacute;rios, que poder&atilde;o, a qualquer momento, enquanto eleg&iacute;veis, solicitar restabelecimento do envio de comunica&ccedil;&otilde;es do ZooMoo Livros</p>
        <p>3.3. Poder&aacute;, a qualquer momento e a crit&eacute;rio exclusivo e iniciativa da empresa parceira do ZooMoo Livros a qual o Participante Usu&aacute;rio est&aacute; vinculado, haver o cancelamento da participa&ccedil;&atilde;o de usu&aacute;rios na medida que estas apontem ao ZooMoo Livros o fim da elegibilidade do Participante Usu&aacute;rio.&nbsp;</p>
        <p>3.4. O cancelamento da elegibilidade do Participante Usu&aacute;rio n&atilde;o afeta o acesso e a leitura dos livros j&aacute; adquiridos, observado o prazo do item 3.5, sendo de propriedade do Participante Usu&aacute;rio os t&iacute;tulos por ele baixados a qualquer tempo. O cancelamento da elegibilidade e por consequ&ecirc;ncia, da participa&ccedil;&atilde;o do Participante Usu&aacute;rio n&atilde;o impede o download futuro, at&eacute; o per&iacute;odo de um ano desde a data de disponibiliza&ccedil;&atilde;o dos t&iacute;tulos no per&iacute;odo de elegibilidade, mesmo ao usu&aacute;rio que tenha se tornado ineleg&iacute;vel.</p>
        <p>3.5. Todo o novo conte&uacute;do adquirido mensalmente pelo Usu&aacute;rio Participante estar&aacute; dispon&iacute;vel para download pelo per&iacute;odo de um (1) ano a contar da data de disponibiliza&ccedil;&atilde;o na plataforma, nos termos do que disp&otilde;e o art. 3&ordm;, inciso II do Decreto n&ordm; 7.962 de 15 de mar&ccedil;o de 2013. Ap&oacute;s este per&iacute;odo, n&atilde;o ser&aacute; poss&iacute;vel para o Usu&aacute;rio participante baixar os t&iacute;tulos disponibilizados em datas anteriores. O conte&uacute;do de meses anteriores &agrave; ades&atilde;o, oferecido como b&ocirc;nus ao Usu&aacute;rio Participante, estar&aacute; dispon&iacute;vel por per&iacute;odos menores, que variam de acordo com a data de in&iacute;cio da disponibiliza&ccedil;&atilde;o do conte&uacute;do na plataforma, independentemente da data de ades&atilde;o do Usu&aacute;rio Participante.</p>
        <p>3.6. Considerando os princ&iacute;pios da informa&ccedil;&atilde;o e transpar&ecirc;ncia, a perda de acesso a conte&uacute;do disponibilizado h&aacute; mais de um ano pelo Participante Usu&aacute;rio n&atilde;o dar&aacute; direito a nenhuma compensa&ccedil;&atilde;o, indeniza&ccedil;&atilde;o ou repara&ccedil;&atilde;o.</p>
        <p>3.7. Os Participantes Usu&aacute;rios dever&atilde;o estar cientes de que o acesso ao ZooMoo Livros e o download de t&iacute;tulos depende de tr&aacute;fego de dados pela rede de dados, estando, pois, sujeito &agrave; consumo de dados, tarifa&ccedil;&atilde;o e encargos pela operadora de telecomunica&ccedil;&otilde;es.</p>
        <p>3.8. O ZooMoo Livros reserva-se o direito de, a qualquer momento e a seu exclusivo crit&eacute;rio, descontinuar, suspender, terminar a oferta da plataforma, ou ainda alterar a quantidade, o formato e a periodicidade dos t&iacute;tulos disponibilizados, mantendo a disponibilidade prevista no item 3.4.</p>
        <p>3.9. Para hip&oacute;tese de encerramento do ZooMoo Livros, ter-se-&aacute; pr&eacute;via comunica&ccedil;&atilde;o de 30 (trinta) dias aos Participantes Usu&aacute;rios.</p>
        <p>3.10. A plataforma ZooMoo Livros &eacute; de uso pessoal e n&atilde;o comercial, da qual os Participantes Usu&aacute;rios poder&atilde;o ser exclu&iacute;dos e/ou ter a plataforma suspensa, definitiva ou temporariamente, pelos seguintes motivos, dentre outros:</p>
        <p>a. compartilhar, ceder, comercializar, divulgar ou imprimir os conte&uacute;dos disponibilizados;</p>
        <p>b. modificar, adaptar, licenciar, transferir, editar, publicar, traduzir, promover publica&ccedil;&atilde;o em qualquer outro meio de comunica&ccedil;&atilde;o, criar outras obras derivadas das disponibilizadas, ou de qualquer forma explorar ou tirar proveito comercial do conte&uacute;do digital da plataforma ZooMoo Livros;</p>
        <p>c. comercializar, locar ou transferir o acesso &agrave; plataforma ZooMoo Livros;</p>
        <p>d. adaptar, modificar, comercializar, distribuir, realizar engenharia no c&oacute;digo fonte da plataforma ou praticar qualquer ato que comprometa, total ou parcialmente, seu funcionamento, disponibilidade e/ou desempenho;</p>
        <p>e. identifica&ccedil;&atilde;o de qualquer ind&iacute;cio ou prova de v&iacute;cio, fraude ou irregularidade na participa&ccedil;&atilde;o Participante Usu&aacute;rio;</p>
        <p>f. por solicita&ccedil;&atilde;o dos pr&oacute;prios Participantes Usu&aacute;rios ou de respons&aacute;vel devidamente habilitado;</p>
        <p>g. por solicita&ccedil;&atilde;o judicial ou de autoridade competente.</p>
        <p>h. por determina&ccedil;&atilde;o e/ou a crit&eacute;rio exclusivo da empresa parceira associada</p>
        <p>i. se apurado o mau uso do ZooMoo Livros, al&eacute;m de infringir a legisla&ccedil;&atilde;o aplic&aacute;vel, especialmente de natureza c&iacute;vel e criminal, bem como afronta aos princ&iacute;pios &eacute;ticos, morais e de boa-f&eacute;, ou caso haja viola&ccedil;&atilde;o de alguma das disposi&ccedil;&otilde;es contidas neste Termo.</p>
        <p><br /></p>
        <p><strong>4. PROPRIEDADE INTELECTUAL</strong></p>
        <p>4.1. Todos os conte&uacute;dos disponibilizados pelo ZooMoo Livros, incluindo a pr&oacute;pria plataforma, est&atilde;o protegidos pelas Leis de Direitos Autorais e Propriedade Intelectual.</p>
        <p>4.2. O Participante Usu&aacute;rio &eacute; o propriet&aacute;rio dos conte&uacute;dos adquiridos e por ele baixados, sendo que, por estarem estes protegidos pelas leis de direitos autorias, &eacute; expressamente proibido ao Participante Usu&aacute;rio reproduzir, ainda que parcialmente, transmitir, veicular, ceder, negociar ou distribuir qualquer t&iacute;tulo.</p>
        <p>4.3. Ao optar fazer download de qualquer t&iacute;tulo, o Participante Usu&aacute;rio concorda em zelar pelos conte&uacute;dos baixados e respeitar os direitos autorais e a propriedade intelectual de autores e da plataforma, assumindo civil e criminalmente a responsabilidade por eventuais viola&ccedil;&otilde;es e danos de natureza moral e material causados a outrem, independente de natureza e extens&atilde;o, inclusive nas hip&oacute;teses de regresso.</p>
        <p><br /></p>
        <p><strong>5. DAS DISPOSI&Ccedil;&Otilde;ES GERAIS</strong></p>
        <p>5.1. O ZooMoo Livros declara que n&atilde;o armazena os dados pessoais dos Participantes Usu&aacute;rios tampouco compartilha com terceiros sua atividade relacionada a visitas e a&ccedil;&otilde;es na plataforma.</p>
        <p>5.2. Os Participantes Usu&aacute;rios reconhecem o direito do ZooMoo Livros, a qualquer tempo e por ato unilateral, de aditar, modificar ou atualizar as regras, termos e condi&ccedil;&otilde;es deste Regulamento. Qualquer aditamento, modifica&ccedil;&atilde;o ou atualiza&ccedil;&atilde;o ser&atilde;o previamente comunicados aos Participantes Usu&aacute;rios, por meio do site ou por correio eletr&ocirc;nico. Caso os Participantes Usu&aacute;rios continuem a usar o ZooMoo Livros depois do aviso de aditamento, modifica&ccedil;&atilde;o ou atualiza&ccedil;&atilde;o deste Regulamento, isso caracterizar&aacute; a aceita&ccedil;&atilde;o das altera&ccedil;&otilde;es implementadas. Excluem-se as modifica&ccedil;&otilde;es que gerem &ocirc;nus ou novas cobran&ccedil;as ao usu&aacute;rio.</p>
        <p>5.3. O ZooMoo Livros poder&aacute;, a qualquer tempo e por ato unilateral, ser suspenso, extinto ou modificado, ou ter sua gest&atilde;o transferida, mediante pr&eacute;via comunica&ccedil;&atilde;o aos Participantes Usu&aacute;rios, por meio do site http://www.zoomoolivros.com.br, por correio eletr&ocirc;nico aos Participantes Usu&aacute;rios. Em qualquer hip&oacute;tese, ser&aacute; resguardada eventual opera&ccedil;&atilde;o comercial j&aacute; conclu&iacute;da pelos Participantes Usu&aacute;rios at&eacute; a data da suspens&atilde;o, extin&ccedil;&atilde;o ou modifica&ccedil;&atilde;o.</p>
        <p>5.4. Os Participantes reconhecem a obrigatoriedade de observar as leis de prote&ccedil;&atilde;o ao direito autoral e propriedade intelectual e concordam em faz&ecirc;-lo, estando sujeitos, em contr&aacute;rio, &agrave;s penalidades c&iacute;veis e criminais aplic&aacute;veis.</p>
        <p>5.5. Os casos omissos neste Regulamento ser&atilde;o tratados diretamente entre os Participantes Usu&aacute;rios e o ZooMoo Livros, observando a legisla&ccedil;&atilde;o pertinente.</p>
        <p>5.6. Qualquer toler&acirc;ncia ao descumprimento ou omiss&atilde;o na execu&ccedil;&atilde;o de seus direitos por parte do ZooMoo Livros, n&atilde;o constituir&aacute; ren&uacute;ncia, inefic&aacute;cia ou nova&ccedil;&atilde;o dos direitos e obriga&ccedil;&otilde;es ora pactuados, nem impedir&aacute; que o ZooMoo Livros ou qualquer dos autores e/ou editores veiculados na plataforma, ainda que extemporaneamente, requeira o cumprimento de tais direitos e obriga&ccedil;&otilde;es, sendo certo que qualquer exce&ccedil;&atilde;o s&oacute; se tornar&aacute; v&aacute;lida- desde que expressamente concedida pelo ZooMoo Livros ou qualquer dos parceiros disponibilizados por meio da plataforma.</p>
        <p>5.7. O ZooMoo Livros n&atilde;o ser&aacute; considerado em mora ou inadimplente em rela&ccedil;&atilde;o a qualquer direito ou obriga&ccedil;&atilde;o previstos neste Regulamento se o motivo do descumprimento decorrer de caso fortuito ou for&ccedil;a maior, na forma estabelecida pelo C&oacute;digo Civil Brasileiro, Lei. n&ordm; 10.406, de 10 de janeiro de 2002 e pelo C&oacute;digo de Prote&ccedil;&atilde;o e Defesa do Consumidor, Lei n&ordm; 8.078 de 11 de setembro de 1990.</p>
        <p>5.8. Na hip&oacute;tese de qualquer das regras, termos ou condi&ccedil;&otilde;es aqui dispostas ser considerada, por qualquer motivo, por ju&iacute;zo ou &aacute;rbitro competente, inv&aacute;lido, inexequ&iacute;vel ou ilegal, no todo ou em parte, a validade e exequibilidade das demais regras, termos e condi&ccedil;&otilde;es, ou de partes deles, n&atilde;o ser&atilde;o afetadas, pois, tratam-se de regras independentes entre si, nos termos do que disp&otilde;e o art. 51, &sect; 2&ordm; da lei 8.078/90.</p>
        <p>5.9. Independentemente do local no territ&oacute;rio, nacional ou internacional, que os Participantes Usu&aacute;rios tenham acesso ao ambiente de internet do ZooMoo Livros, este Regulamento ser&aacute; regido, interpretado e executado de acordo com as leis da Rep&uacute;blica Federativa do Brasil, independentemente dos conflitos dessas leis com leis de outros estados ou pa&iacute;ses, sendo competente a Justi&ccedil;a Brasileira para dirimir qualquer d&uacute;vida decorrente deste instrumento. O aceite ao presente Regulamento estabelece a ren&uacute;ncia dos Participantes Usu&aacute;rios &agrave; compet&ecirc;ncia de qualquer outra autoridade judici&aacute;ria, FICANDO DESDE J&Aacute; ELEITO O FORO DO DOMIC&Iacute;LIO DO PARTICIPANTE como competente para dirimir qualquer quest&atilde;o oriunda do presente Regulamento, por mais privilegiado que seja ou venha a ser.</p>
        <p>&nbsp;</p>
      </div>
      <div className="modal-button-regulamento-container">
        <button
          onMouseEnter={() => playSong(playSong(songNames.TAP))}
          onClick={() => {
            playSong(songNames.TAP)
            props.onRequestClose()
          }}
        >
          FECHAR
        </button>
      </div>
    </div>
  );
};

export default Regulamento;
