import React, { useState } from "react";
import { default_server } from "./../../services/api";
import TweenOne from "rc-tween-one";
import Drawer from "react-drag-drawer";
import "./../../assets/styles/BookDetail.css";
import Copyrights from "../copyrights";
import { songNames } from "../../enums/songs";
import { pauseSong, playSong } from '../../utils/song'

const BookDetail = props => {
  let BOOK = 0;

  const isSurprise = props.appState === "firsttimevisit" ? true : false;
  const currentBookID = props.currentBookID;
  for (let i = 0; i < props.payload.listData.length; i++) {
    const element = props.payload.listData[i];
    if (element.bookId === currentBookID) {
      BOOK = i;
    }
  }

  const TOKEN = props.payload.token;
  const ITEM = props.payload.listData[BOOK];
  const GENDER = ""; //ITEM.bookAuthorGender === "F"? "Autora: " : "Autor: "
  const ILLUSTRATOR =
    ITEM.bookIllustrator.trim() === ITEM.bookAuthor.trim()
      ? ""
      : ITEM.bookIllustrator || "Carregando...";
  const AUTHOR = ITEM.bookAuthor || "Carregando...";
  const TITLE = ITEM.bookTitle || "Carregando...";
  const BOOK_URL = ITEM.bookDownloadFile || "#";
  const content = `${GENDER} ${AUTHOR} \n  ${ILLUSTRATOR} `;

  const [show_how, toggle_how] = useState(false);
  const [show_download, toggle_download] = useState(false);

  const axios = require("axios");
  const postDownloadCounter = type => {
    axios.post(
      `${default_server}/quembaixou`,
      {
        livro: TITLE,
        token: TOKEN,
        produto: `${process.env.REACT_APP_DEFAULT_PRODUCT}`,
        tipo: type
      },
      {
        timeout: 10000,
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  };

  return (
    <TweenOne
      paused={isSurprise}
      animation={{ opacity: 1 }}
      id="book_detail"
      className={`book_detail_container`}
    >
      <p className="book_detail_title">{TITLE}</p>
      <p className="book_detail_infos">{content}</p>

      <br />

      <div class="button-container">
        <button
          id="how-to-read"
          disabled={!("bookDownloadFile" in ITEM)}
          onMouseEnter={() => playSong(songNames.TAP)}
          onClick={() => {
            playSong(songNames.TAP)
            playSong(songNames.OCEAN_BUBBLES)
            props.changeReader(ITEM);
            postDownloadCounter(2);
          }}
        >
          LER ONLINE
        </button>
        <a
          href={"#"}
          onMouseEnter={() => playSong(songNames.TAP)}
          onClick={() => {
            playSong(songNames.TAP)
            pauseSong(songNames.MAIN_BACKGROUND)
            playSong(songNames.DOWNLOAD)
            toggle_download(true);
            postDownloadCounter(1);
            setTimeout(() => {
              playSong(songNames.MAIN_BACKGROUND)
              toggle_download(false);
              window.open(BOOK_URL, "_self");
            }, 5000);
          }}
        >
          <button disabled={!("bookDownloadFile" in ITEM)} id="download">
            BAIXAR
          </button>
        </a>
      </div>

      <br />

      <a
        className="book_detail_link"
        href={"#"}
        onMouseEnter={() => playSong(songNames.TAP)}
        onClick={() => {
          playSong(songNames.TAP)
          toggle_how(true)
        }}
      >
        {` SAIBA MAIS `}
      </a>

      <Drawer dontApplyListeners={true} open={show_how} onRequestClose={() => toggle_how(false)}>
        <div className="modal">
          <div className="modal-container">
            <h2>SAIBA MAIS</h2>
            <p style={{ textAlign: "left" }}>
              Você pode ler seu livro online aqui mesmo na plataforma, se estiver conectado à internet, e pode também ouvir a narração da história.
            </p>

            <p style={{ textAlign: "left" }}>
              Se preferir baixar para ler offline, você vai precisar ter um leitor digital (e-reader) instalado. Há muitas opções gratuitas em sua loja de aplicativos. Recomendamos o Play Livros para celulares e tablets Android, o Livros, para celulares e tablets iOS, e o Adobe Digital Editions, para computadores. Se o livro não for carregado diretamente para a biblioteca do seu leitor digital, procure-o na área de downloads do seu dispositivo e faça o carregamento manual.
            </p>

            <p style={{ textAlign: "left" }}>
              Você tem 12 meses a partir da disponibilização do livro para fazer o download. Durante este período, você pode ler e ouvir online sempre que quiser. Os livros baixados são seus, mas lembre-se que todo o material é protegido por direitos autorais, por isso é proibido enviar a terceiros, reproduzir, distribuir e comercializar.
            </p>
          </div>
          <div className="modal-button-container">
            <button
              onMouseEnter={() => playSong(songNames.TAP)}
              onClick={() => {
                playSong(songNames.TAP)
                toggle_how(false)
              }}
              >
                FECHAR
              </button>
          </div>
        </div>
      </Drawer>

      <Drawer dontApplyListeners={true} open={show_download}>
        <Copyrights />
      </Drawer>
    </TweenOne>
  );
};

export default BookDetail;
