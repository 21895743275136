import React, { Component } from "react";
// import TweenOne from "rc-tween-one";
import "./../../assets/styles/Preloader.css";

class SimpleLoader extends Component {
  static defaultProps = {
    appState: null
  };

  state = {
    isTimeToRemoveLoader: false
  };

  componentDidUpdate() {
    const stateAppLoaded =
      this.props.appState === "inicializing" || this.props.appState === "loading"
        ? false
        : true;
    if (stateAppLoaded && !this.state.isTimeToRemoveLoader) {
      setTimeout(() => {
        this.setState({ isTimeToRemoveLoader: true });
      }, 1000);
    }
  }

  render() {
    const { isTimeToRemoveLoader } = this.state;
    const screenWidth = window.innerWidth;
    const stateAppLoaded =
      this.props.appState === "inicializing" || this.props.appState === "loading"
        ? false
        : true;
    if (isTimeToRemoveLoader) {
      return null;
    }
    return (
      <div className={`preloader_container ${stateAppLoaded ? 'removeOpacity' : null}`}>
        <div
          className="preloader_animate"
        />
      </div>
    );
  }
}

export default SimpleLoader;
